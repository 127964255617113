<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-light" id="nav">
		<div class="container">
			<a class="navbar-brand" href="#">
				<img src="../assets/GAMA-logo.png" alt="" />
			</a>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
				<div class="navbar-nav">
					<router-link to="/" class="nav-link">Home</router-link>
					<router-link :to="{ name: 'Produk'}" class="nav-link">Training & Consulting</router-link>
					<router-link to="/berita" class="nav-link">News</router-link>
					<router-link to="/klien" class="nav-link">Clients</router-link>
					<router-link to="/about" class="nav-link">About Us</router-link>
					<router-link to="/contact" class="nav-link">Contact Us</router-link>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'NavbarEn',

    setup() {
		let categories = ref([]);

		onMounted(() => {
			axios.get('getKategoriProduk')
			.then((result) => {
				categories.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

		return{
			categories
		}
	},
	data() {
        return {
            activeLanguage: this.lang
        }
    },
	methods: {
        selectLanguage(lang) {
            this.activeLanguage = lang;
            // this.lang = lang;
            
            localStorage.setItem("lang", this.lang);
        },
		load(load){
            this.load = load;
			location.reload()
        }
    },
}
</script>

<style>
    /* ======= Navbar ======= */
	#nav{
		background: white;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
		/* height: 150px; */
		padding: 20px 0;
	}
	#nav .navbar-brand img{
		width: 100px;
		height: auto;
	}

	#nav a {
		font-weight: 600;
		color: var(--primary-);
		text-transform: uppercase;
	}
	#nav a.dropdown-toggle{
		margin-top: -8px;
	}

	#nav a.router-link-exact-active {
		color: var(--secondary-);
	}

	#nav .lang{
		display: flex;
	}
	#nav .lang .nav-link:hover{
		cursor: pointer;
	}
	#nav .lang .nav-link.link{
		color: var(--secondary-);
		opacity: 0.6;
		padding: 5px;
		transition: 0.5s ease-in-out;
		background: none;
		border: none;
	}
	#nav .lang .nav-link.link:hover{
		opacity: 1;
	}
	#nav .lang .nav-link.link__active{
		color: var(--secondary-);
		opacity: 1;
	}
	#nav .bm{
		display: none !important;
	}
	
	@media (max-width: 768px)
	{
		#nav .navbar-brand img{
			width: 70px;
		}
		#nav .lang{
			justify-content: center;
		}
		#nav .do{
			display: none !important;
		}
		#nav .bm{
			display: flex !important;
			border: 1px solid #3e3f9550;
			padding: 5px;
			background: var(--light-grey-);
		}
	}
	/* ======= Navbar ======= */
</style>
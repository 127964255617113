<template>
    <div class="footer">
        <p>Copyright Gama Management Consulting 2021. All Right Reseved</p>
    </div>
</template>

<script>
export default {
    name : 'Footer'
}
</script>

<style>
    .footer{
        background-color: white;
        box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin-top: 30px;
    }
    .footer p{
        color: var(--dark-grey);
        text-transform: uppercase;
        text-align: center;
        padding-top: 15px;
        font-size: 10pt;
        font-weight: 600;
    }
</style>
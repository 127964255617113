import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import './assets/css/main.css'
import i18n from './i18n'

 
import VueSplide from '@splidejs/vue-splide';


const app = createApp(App);

import axios from 'axios'

// ==== Endpoint Staging BIT House ====
// axios.defaults.baseURL = 'https://admingama.bithouse.id/api/'

// ==== EndPoint Live Gama ====
axios.defaults.baseURL = 'https://admin.gamaconsultant.id/api/'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Method'] = 'GET', 'POST', 'OPTIONS', 'PUT', 'DELETE';
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

import AOS from "aos";
import "aos/dist/aos.css";
app.AOS = new AOS.init();

// app.config.globalProperties.lang = 'en';
app.config.globalProperties.lang = 'id';

// ==== Global Image Url Staging Gama BIT House
// app.config.globalProperties.imageUrl = 'https://admingama.bithouse.id/';

// ==== Global Image Url Live Gama
app.config.globalProperties.imageUrl = 'https://admin.gamaconsultant.id/';
app.config.globalProperties.noImage = './assets/no-image.jpg';

app.use( VueSplide )

app.use(i18n).use(router).use(router).mount('#app')

<template>
    <div class="News">
        <router-link :to="'/berita/'+beritaSaya.id" class="l-b">
			<div class="b-list">
				<div class="thumb" v-if="beritaSaya.image === null">
					<img :src="noImage" alt="">
				</div>
				<div class="thumb" v-else>
					<img :src="imageUrl+beritaSaya.image" alt="">
				</div>
				<div class="content c_title">
					<h5 v-if="this.lang == 'en'">
						<span v-if="beritaSaya.judul_berita_en.length>=40">{{beritaSaya.judul_berita_en.substring(0,40)+'...'}}</span>
						<span v-else>{{beritaSaya.judul_berita_en}}</span>
					</h5>
					<h5 v-else-if="this.lang == 'id'">
						<span v-if="beritaSaya.judul_berita.length>=40">{{beritaSaya.judul_berita.substring(0,40)+'...'}}</span>
						<span v-else>{{beritaSaya.judul_berita}}</span>
					</h5>
					<p>
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
							<g id="clock" transform="translate(-2.5 -2.5)">
								<path id="Path_213" data-name="Path 213" d="M14,8.5A5.5,5.5,0,1,1,8.5,3,5.5,5.5,0,0,1,14,8.5Z" fill="none" stroke="#a2a2a2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
								<path id="Path_214" data-name="Path 214" d="M18,9v3.3l2.2,1.1" transform="translate(-9.5 -3.8)" fill="none" stroke="#a2a2a2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
							</g>
						</svg>
						{{ dateTime(beritaSaya.created_at) }}
					</p>
				</div>
			</div>
		</router-link>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "News",
    props: ["beritaSaya"],

    
	methods: {
		dateTime(value) {
			return moment(value).format("DD-MM-YYYY h:mm A");
		},
        
	}
}
</script>

<style>
	.b-list .c_title h5{
		font-size: 12pt;
		margin-top: -5px;
	}
	.b-list .c_title p{
		font-size: 10pt;
	}
</style>
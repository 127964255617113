import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Produk from '../views/Produk.vue'
import ProdukDetail from '../views/ProdukDetail.vue'
import Berita from '../views/Berita.vue'
import BeritaDetail from '../views/BeritaDetail.vue'
import Klien from '../views/Klien.vue'
import KlienDetail from '../views/KlienDetail.vue'
import About from '../views/About.vue'
// import Contact from '../views/Contact.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/training-and-consulting',
    name: 'Produk',
    component: Produk
  },
  {
    path: '/produk-detail/:id',
    name: 'ProdukDetail',
    component: ProdukDetail
  },
  {
    path: '/berita',
    name: 'Berita',
    component: Berita
  },
  {
    path: '/berita/:id',
    name: 'BeritaDetail',
    component: BeritaDetail
  },
  {
    path: '/klien',
    name: 'Klien',
    component: Klien
  },
  {
    path: '/klien/:id',
    name: 'KlienDetail',
    component: KlienDetail
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: Contact
  // },

  {
    path: '/about',
    name: 'about',
    component: About
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
